<template>
  <div class="contentsWater">
    <div class="mainTitle">
      居家水質查詢：{{ addr }}
    </div>

    <div class="cardTitle">
      政府開放資料
    </div>
    <div class="dataCard el-card">
      <el-row class="dataBox">
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            TDS總溶解固體量
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.hf_melt || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            總硬度
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.hf_hardness || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            自由有效餘氯
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.hf_chlorineleft || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            濁度
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.hf_roil || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="cardTitle">
      居家檢測數值
    </div>
    <div class="dataCard el-card">
      <el-row class="dataBox">
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            TDS
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.ins_tds || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            餘氣
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.ins_chlorine || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="6"
          :md="6"
        >
          <div class="title">
            PH
          </div>
          <div class="numBox">
            <span class="num">{{ waterQuantityData.ins_ph || 0 }}</span><span class="unit">PPM</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="infoCard el-card">
      <p>1. 此數值為您居家鄰近之淨水場數據庫，各種水質資料來源於「台灣自來水公司」及「台北市資料大平臺」公開資料，僅作為居家水質參數</p>
      <p>2. 因國內的配水系統及居家管線之長短、材質、時間期限不一，可能會影響終端用水水質狀況。</p>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { GetCustomerWaterValueCheck } from '../api/api'
export default {
  components: {},
  data () {
    return {
      addr: '',
      waterQuantityData: {
        hf_customerproductId: '',
        hf_manufacturingno: '',
        ins_tds: 0,
        ins_chlorine: 0,
        ins_ph: 0,
        hf_melt: 0,
        hf_hardness: 0,
        hf_chlorineleft: 0,
        hf_roil: 0
      }
    }
  },
  async mounted () {
    const CustProdData = await this.getUserCustProdData(this.getUserLineId(this.$route))
    if (CustProdData && CustProdData[0]) {
      this.addr = `${_.get(CustProdData, '0.Addr').split('區')[0].split('-')[1]}區`
      const CustProdId = _.get(CustProdData, '0.CustProdId')
      const result = await GetCustomerWaterValueCheck({ CustProdId })
      // const result = await GetCustomerWaterValueCheck({ CustProdId: 'FAA7ACD3-C03A-EB11-81B5-005056AF7974' })
      this.waterQuantityData = _.merge(this.waterQuantityData, _.get(result, 'data.Data.0'))
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.mainTitle {
    color: black;
    font-size: 24px;
    margin: 0 0 15px 0;
}

.cardTitle {
    margin: 10px 0;
    font-size: 16px;
}

.el-card {
    margin: 15px 0;
}

.dataCard {
    color: black;
    padding: 15px;
    background-color: #00000012;
    box-shadow: 5px 5px 10px -3px #0000006b;
    .num {
      font-size: 24px;
      padding: 0 5px;
    }
    .title {
      padding: 10px 0;
    }
}

.infoCard {
    margin-top: 35px;
    background-color: #ffffff2e;
    padding: 10px;
    line-height: 16px;
    box-shadow: 5px 5px 10px -3px #0000006b;
    p {
      margin: 15px 0;
      color: black;
    }
}

.contentsWater {
    background-color: #ffffffe6;
    padding: 15px;
    border-radius: 15px;
}

@import '../assets/styles/mixin.scss';
.contents {
  .el-card {
    border-radius: 1rem;
  }
@media screen and (max-width: 992px) {
  .el-card{
    .el-row{
      .el-col:last-child {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
          span {
            bottom: -2rem;
          }
      }

    }
  }
}
}
</style>
